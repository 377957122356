<template>
  <div class="manager-container">
    <div class="button-block">
      <el-button
        type="primary"

        round
        plain
        @click="refreshDataMixin()">
        {{ $t('refresh') }}
      </el-button>
      <el-button
        v-permission="'delete'"
        type="danger"

        round
        plain
        @click="deleteFile">
        {{ $t('delete') }}
      </el-button>
    </div>
    <div class="table-block">
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        height="100%"
        stripe
        @selection-change="onSelectionChangeMixin">
        <base-no-data slot="empty"></base-no-data>
        <el-table-column
          type="selection"
          align="center"
          width="55">
        </el-table-column>
        <el-table-column
          type="index"
          :label="$t('serialNum')"
          align="center"
          width="50">
        </el-table-column>
        <el-table-column
          prop="originalName"
          :label="$t('filename')"
          align="center"
          width="350"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="bizId"
          :label="$t('bizId')"
          align="center"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="bizType"
          :label="$t('bizType')"
          align="center"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="userId"
          :label="$t('userId')"
          align="center">
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('createTime')"
          width="160"
          align="center">
        </el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          :label="$t('operate')"
          width="180">
          <template slot-scope="scope">
            <!--预览-->
            <el-button
              v-permission="'view'"
              type="text"

              @click="previewFile(scope.row)">
              {{ $t("preview") }}
            </el-button>
            <!--删除-->
            <el-button
              v-permission="'delete'"
              style="color: #f56c6c"
              type="text"

              @click="deleteFileMixin(scope.row.id)">
              {{ $t("delete") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChangeMixin"
        @current-change="handleCurrentChangeMixin"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :current-page="pageIndex"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <file-preview
      :visible.sync="filePreviewVisible"
      :file="currentFile">
    </file-preview>
  </div>
</template>

<script>
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'

export default {
  name: 'index',
  mixins: [baseMixin, baseTableMixin],
  data () {
    return {
      queryUrl: '',
      currentFile: {},
      filePreviewVisible: false
    }
  },
  created () {
    this.queryUrl = this.$apis.queryFile
    this.getTableDataMixin()
  },
  methods: {
    previewFile (file) {
      this.currentFile = file
      this.filePreviewVisible = true
    },
    deleteFile () {
      if (this.selection.length) {
        const ids = this.selection.map((item) => item.id)
        return this.deleteFileMixin(ids)
      }
      return this.$message.error(this.$t('selectAtLeastOneData'))
    }
  }
}
</script>

<style lang="scss" scoped>
.manager-container {
  height: 100%;
  width: 100%;

.table-block {
  // width: 100%;
  height: calc(100% - 74px);
  // margin-bottom: 10px;
  // border-radius: 5px;
  // overflow: hidden;
}

  .button-block {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;
  }
}
</style>
